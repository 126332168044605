import auditEventRepository from "@/api/repositories/auditEvent";
import orderSummaryRepository from "@/api/repositories/orderSummary";
import { AUDIT_EVENT } from "@/api/endpoints";

export default {
  fetchOrderEvent: (orderId, filters) =>
    auditEventRepository.get(`${AUDIT_EVENT.ORDER_EVENTS}/${orderId}`, { params: filters }),

  fetchOrders: (orderId, filters) =>
    orderSummaryRepository.get(`${AUDIT_EVENT.ORDER_SUMMARY}/${orderId}`),
};
