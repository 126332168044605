/**
 * @file Mapper between API endpoints and internal BE error codes
 * Rare exception with duplicate internal error code for Endpoint: mapping will be with HTTP status code
 *
 * ! UI Error Dictionary confluence page is no longer up to date but can be used as general overview of different use cases
 * @link https://confluence.nike.com/display/OSD/UI+Error+Dictionary
 */

import {
  OSD,
  TEMPLATE,
  FILE,
  ORDER,
  ORDER_REPO,
  COMP,
  PRODUCT,
  EMPLOYEE,
  LO_FILTERING,
  CUSTOMER,
  MAINTENANCE,
  EXCEPTIONS,
} from "@/api/endpoints";

const ordersSaveErrorCodes = {
  VALIDATION_ERROR: "formContainsErrors",
  UNKNOWN_USER: "systemErrorTryAgainOrSupport",
  FORBIDDEN_DATA_ERROR: "ordersSelectedNotAvailable",
  RESOURCE_NOT_FOUND: "systemErrorTryAgainOrSupport",
  ORDER_STATUS_FORBIDDEN_ERROR: "approvedRejectedOrder",
  CONCURRENT_MODIFICATION_ERROR: "someoneModifiedOrderRefreshPage",
  LINE_STATUS_FORBIDDEN_ERROR: "linesModifiedStatusRework",
  OFOA_ERROR: "systemErrorTryAgainOrSupport",
};

const employeeFindErrorCodes = {
  VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
  500: {
    CLIENT_CONNECTION_ERROR: "unableToFindUser",
  },
  503: {
    CLIENT_CONNECTION_ERROR: "unableToFindUser",
  },
};

const exceptionGroupsCases = {
  FORBIDDEN_DATA_ERROR: "businessTypesSelectedNotAvailable",
  500: {
    CLIENT_CONNECTION_ERROR: "unableToGetCmData",
  },
  503: {
    CLIENT_CONNECTION_ERROR: "caseDataNotAvailableTryAgain",
  },
};

/**
 * Leaves structure:
 * Key: Backend Error Code | HTTP Status Code
 * Value: Points to Key in src/enums/language/locales
 */
const errorEndpointsMessagesMapper = {
  [TEMPLATE.DOWNLOAD_TEMPLATE]: {
    VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
    FORBIDDEN_DATA_ERROR: "orderTypeNotAvailable",
    RESOURCE_NOT_FOUND: "selectedOrderTypeNotExist",
  },
  [FILE.UPLOAD_FILES]: {
    INAPPROPRIATE_FILES_AND_PARAMS: "systemErrorContactSupport",
    FILES_UPLOAD_S3_ERROR: "systemErrorContactSupport",
  },
  [FILE.NOT_SUCCEEDED_FILES]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
  },
  [FILE.DELETE_FILE]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    FORBIDDEN_DATA_ERROR: "onlyCreatorFileAllowedDelete",
    RESOURCE_NOT_FOUND: "fileDeletedReloadPage",
  },
  [ORDER.GET_BY_FILE_NAMES]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
  },
  [ORDER.SAVE_ALL]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    FORBIDDEN_DATA_ERROR: "orderTypeNotAvailableToYou",
  },
  [ORDER.DELETE]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    VALIDATION_FAILED: "selectedOrdersCanNotDeleted",
    FORBIDDEN_DATA_ERROR: "ordersSelectedNotAvailable",
  },
  [ORDER.SUBMISSION]: {
    FORBIDDEN_DATA_ERROR: "ordersSelectedNotAvailable",
    RESOURCE_NOT_FOUND: "systemErrorTryAgainOrSupport",
  },
  [ORDER.SAVE]: {
    ...ordersSaveErrorCodes,
  },
  [ORDER.SAVE_AND_SUBMIT]: {
    ...ordersSaveErrorCodes,
  },
  [ORDER_REPO.ORDERS_SUMMARY]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
  },
  [ORDER_REPO.ORDERS]: {
    VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    INTERNAL_ERROR: "internalServerError",
    500: {
      CLIENT_CONNECTION_ERROR: "unableToGetOrderDataSummarySupport",
    },
    503: {
      CLIENT_CONNECTION_ERROR: "unableToGetOrderDataSummaryTryAgain",
    },
  },
  [ORDER.ORDERS]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    FORBIDDEN_DATA_ERROR: "ordersSelectedNotAvailable",
    RESOURCE_NOT_FOUND: "unableToFindOrder",
  },
  [COMP.MY_APPROVALS]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
  },
  [COMP.APPROVAL]: {
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    MISSING_ORDER: "unableToFindOrder",
    NOT_A_COMP_ORDER: "hmmLooksOrderToEditNotZFRE",
    NO_APPROVALS_EXPECTED: "orderCanNotBeApproved",
    APPROVAL_ALREADY_SUBMITTED: "alreadyApprovedOrder",
    REDUNDANT_STATUS: "systemErrorTryAgainOrSupport",
    MISSING_STATUS: "systemErrorTryAgainOrSupport",
    REDUNDANT_LINES: "systemErrorTryAgainOrSupport",
    NO_APPROVALS_FOUND: "notAllowedToApprove",
    APPROVAL_HAS_PENDING_LINES: "notAllowedToApprove",
    NO_SUCH_LINE: "systemErrorTryAgainOrSupport",
    BAD_STATUS: "systemErrorTryAgainOrSupport",
    CONCURRENT_MODIFICATION_ERROR: "someoneModifiedOrderRefreshPage",
  },
  [PRODUCT.AVAILABILITY_DETAILS]: {
    FORBIDDEN_DATA_ERROR: "salesOrgSelectedNotAvailable",
    400: {
      PRODUCT_CODE_OR_GTIN_REQUIRED: "productCodeOrGtinRequired",
      PRODUCT_CODE_GTIN_INVALID: "productCodeGtinInvalid",
      SALES_ORG_REQUIRED: "salesOrgRequired",
      SALES_CHANNEL_REQUIRED: "salesChannelRequired",
      PRODUCT_CODE_INVALID: "productCodeInvalid",
      SOURCING_METHOD_REQUIRED: "sourcingMethodRequired",
      SOURCING_METHOD_INVALID: "sourcingMethodInvalid",
      CONTRACT_NUMBER_REQUIRED: "contractNumberRequired",
      CONTRACT_NUMBER_FORBIDDEN: "contractNumberForbidden",
    },
    500: {
      CLIENT_CONNECTION_ERROR: "eipFailed",
    },
    503: {
      CLIENT_CONNECTION_ERROR: "eipNotAvailable",
    },
  },
  [EXCEPTIONS.GET_SELECTED_POS]: {
    400: {
      VALIDATION_FAILED: "invalidPos",
    },
  },
  [PRODUCT.MATERIAL_AVAILABILITY]: {
    VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
    NO_MATERIAL_DATA: "unableToFindMaterial",
    NOT_YET_OFFERED: "materialNotOfferedCRD",
    NO_SIZES: "unableToFindSizesForMaterial",
    FORBIDDEN_DATA_ERROR: "salesOrgSelectedNotAvailable",
    SAMPLES_NOT_YET_OFFERED: "samplesMaterialNotOffered",
    500: {
      CLIENT_CONNECTION_ERROR: "unableToGetMaterialNDFSupport",
    },
    503: {
      CLIENT_CONNECTION_ERROR: "availabilityServiceUnavailable",
    },
  },
  [EMPLOYEE.FIND_BY_EMAIL]: {
    ...employeeFindErrorCodes,
  },
  [EMPLOYEE.FIND_BY_ID]: {
    ...employeeFindErrorCodes,
  },
  [EMPLOYEE.LOOKUP]: {
    ...employeeFindErrorCodes,
  },
  [EMPLOYEE.MANAGERS]: {
    RESOURCE_NOT_FOUND: "unableToFindManagers",
    500: {
      CLIENT_CONNECTION_ERROR: "unableToFindManagers",
    },
    503: {
      CLIENT_CONNECTION_ERROR: "unableToFindManagers",
    },
  },
  [LO_FILTERING.EXCEPTION_GROUPS]: {
    ...exceptionGroupsCases,
  },
  [LO_FILTERING.EXCEPTION_CASES]: {
    ...exceptionGroupsCases,
  },
  [CUSTOMER.DETAILS]: {
    VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
    FORBIDDEN_DATA_ERROR: "unableToFindBusinessTypes",
  },
  [LO_FILTERING.USER_ACTIVITY_OVERVIEW]: {
    VALIDATION_FAILED: "systemErrorTryAgainOrSupport",
    UNKNOWN_USER: "systemErrorTryAgainOrSupport",
    500: {
      CLIENT_CONNECTION_ERROR: "unableToGetOrderDataSummarySupport",
    },
    503: {
      CLIENT_CONNECTION_ERROR: "unableToGetOrderDataSummaryTryAgain",
    },
  },
  [OSD.GET_GEO_BY_NAME]: {
    RESOURCE_NOT_FOUND: "selectedGeoNameDoesNotExist",
  },
  [MAINTENANCE.ENABLE_OUTAGE]: {
    400: {
      VALIDATION_FAILED: "outageValidation",
    },
  },
};

function getMappedEndpoint(endpoint = "") {
  const mappedEndpointErrors = Object.keys(errorEndpointsMessagesMapper).find(
    (mappedEndpoint) => mappedEndpoint === endpoint
  );

  if (mappedEndpointErrors) {
    return mappedEndpointErrors;
  }
  const slicedEndpoint = removeDynamicQueryString(endpoint);

  return Object.keys(errorEndpointsMessagesMapper).find(
    (mappedEndpoint) => mappedEndpoint === slicedEndpoint
  );
}

function removeDynamicQueryString(endpoint = "") {
  if (endpoint.indexOf("?") !== -1) {
    return endpoint.slice(0, endpoint.indexOf("?"));
  }

  return endpoint.slice(0, endpoint.lastIndexOf("/"));
}

function removeFirstTrailingSlash(endpoint = "") {
  return endpoint[0] === "/" ? endpoint.slice(1, endpoint.length) : endpoint;
}
/**
 * Get error messages for the given endpoint
 *
 * @param {string} [requestedEndpoint='']
 * @return {object|undefined} Object with Error codes keys and i18n messages values
 */
export function getEndpointMappedErrorMessages(requestedEndpoint = "") {
  return errorEndpointsMessagesMapper[
    getMappedEndpoint(removeFirstTrailingSlash(requestedEndpoint))
  ];
}
