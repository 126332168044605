const routes = {
  ROOT: "/",
  MOC: {
    L1: "/massOrderCreation/overview",
    L2: "/massOrderCreation/details",
  },
  SOC: {
    BASE: "/orderCreation",
    TEMPLATE_SELECTION: "/orderCreation/selection",
  },
  MY_ORDERS: {
    BASE: "/myOrders",
  },
  MY_APPROVALS: {
    BASE: "/myApprovals",
  },
  AUDIT_EVENT: {
    BASE: "/audit-event",
    ORDERS: "orders",
    EVENTS: "events",
  },
  ORDER_REPROCESS: "/order-reprocess",
  CALLBACK: "/login/callback",
  NOT_ALLOWED: "/not-allowed",
  RESOURCES: "/resources",
  STUB_PAGE_CONTROLS: "/stubPageControls",
  AVAILABILITY: "/availability",
  PLANNED_OUTAGE: "/planned-outage",
  SESSION_EXPIRED: "/session-expired",
  SERVICE_DOWN: "/service-down",
  EXCEPTIONS: "/exceptions",
  PURCHASE_ORDER: "/purchase-order",
  ORDER_LIST_BY_STATUS: "/orders/status",
  ORDER_LIST_BY_TYPE: "/orders/type",
  ORDER_DETAIL_BY_STATUS: "/order/status",
  ORDER_DETAIL_BY_TYPE: "/order/type",
};

export const redesignedRoutes = [routes.ROOT];

export default routes;
