import moment from "moment";
import { FIELD_KEYS } from "@/pages/AuditEvent/AuditEventFilters/config.ts";

export const updateFieldsItems = ({ filterForm, geos }) => {
  filterForm.set("geos", geos);
};

export const validateField = (field) => {
  const { pattern, errorMessage } = field.validation;

  const regex = new RegExp(pattern);
  const valuesToCheck = field.value.split(",").map((value) => value.trim());

  const hasErrors = valuesToCheck.some((value) => !regex.test(value));

  return hasErrors ? errorMessage : "";
};

const getTimeSubtractObject = (time) => {
  const timeObject = {};

  switch (time) {
    case "15 Minutes":
      timeObject.minutes = 15;
      break;
    case "30 Minutes":
      timeObject.minutes = 30;
      break;
    case "1 Hour":
      timeObject.hours = 1;
      break;
    case "4 Hours":
      timeObject.hours = 4;
      break;
    case "8 Hours":
      timeObject.hours = 8;
      break;
    case "12 Hours":
      timeObject.hours = 12;
      break;
    case "24 Hours":
      timeObject.hours = 24;
      break;
    case "Yesterday":
      timeObject.days = 1;
      break;
    case "Last 7 Days":
      timeObject.days = 7;
      break;
    case "Last 15 Days":
      timeObject.days = 15;
      break;
    case "Last 30 Days":
      timeObject.days = 30;
      break;
  }

  return timeObject;
};

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
};

export const getDateRange = (filters) => {
  const dates = {};
  const dateType = filters.find((param) => param.key === FIELD_KEYS.ORDER_RECEIVED_DATE)?.value;

  if (dateType === "Preset") {
    const preset = filters.find((param) => param.key === FIELD_KEYS.PRESET)?.value;
    const timeObject = getTimeSubtractObject(preset);
    dates.createdOnBefore = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
    dates.createdOnAfter = moment().subtract(timeObject).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
  } else if (dateType === "Custom Date Range") {
    const createdOnAfter = filters.find((param) => param.key === FIELD_KEYS.START_TIME).value;
    const createdOnBefore = filters.find((param) => param.key === FIELD_KEYS.END_TIME).value;
    dates.createdOnAfter = formatDate(createdOnAfter);
    dates.createdOnBefore = formatDate(`${createdOnBefore} 23:59:59`);
  }

  return dates;
};

export const getFieldValue = (fields, key) => {
  return fields.find((field) => field.key === key)?.value;
};
export const validateRequiredFields = (fields) => {
  const orderId = getFieldValue(fields, FIELD_KEYS.ORDER_UUID);
  const poNumber = getFieldValue(fields, FIELD_KEYS.PO_NUMBER);
  const orderType = getFieldValue(fields, FIELD_KEYS.ORDER_TYPE);

  return orderId || poNumber || orderType;
};
