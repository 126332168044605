export const FIELDS_ENUM = {
  // Order List
  receivedDate: "receivedDate",
  lineCount: "lineCount",
  soldToParty: "soldToParty",
  shipToParty: "shipToParty",
  status: "status",
  exceptionErrorInfo: "exceptionErrorInfo",
  orderSentTime: "orderSentTime",
  orchestrationTimeTaken: "orchestrationTimeTaken",

  // Order Detail - Header
  moduleName: "moduleName",
  validationstepName: "validationstepName",
  url: "url",
  statusCode: "statusCode",
  validationStepTimeTaken: "validationStepTimeTaken",

  // Audit Event - Orders
  orderUuid: "orderUuid",
  parentOrderId: "parentOrderId",
  baseOrderId: "baseOrderId",
  latestStatusDate: "latestStatusDate",
  customerPo: "customerPo",
  geoLocation: "geoLocation",

  // Audit Event - Order Events
  eventName: "eventName",
  eventReceivedDate: "eventReceivedDate",
  exceptionFlag: "exceptionFlag",
  headerOrLine: "headerOrLine",
  sizeDetail: "sizeDetail",
  fieldName: "fieldName",
  oldValue: "oldValue",
  augmentedValue: "augmentedValue",

  //Order Reprocess
  dateTime: "dateTime",
  orderReprocessOrderUuid: "orderReprocessOrderUuid",
  orderReceivedDate: "orderReceivedDate",
  preset: "preset",
  defect: "defect",
  comments: "comments",
  startTime: "startTime",
  endTime: "endTime",
  actions: "actions",

  startDate: "startDate",
  endDate: "endDate",
  orderType: "orderType",
  poType: "poType",
  orderId: "orderId",
  poNumber: "poNumber",
  purchaseOrderNumber: "purchaseOrderNumber",
  sapOrderNumber: "sapOrderNumber",
  ofoaOrderId: "ofoaOrderId",
  orderStatus: "orderStatus",
  createDate: "createDate",
  createdBy: "createdBy",
  sourceSystem: "sourceSystem",
  soldTo: "soldTo",
  shipTo: "shipTo",
  dateModified: "dateModified",
  modifiedBy: "modifiedBy",
  crd: "crd",
  crdLineLevel: "crdLineLevel",
  cancelDate: "cancelDate",
  cancelDateLineLevel: "cancelDateLineLevel",
  validFromDate: "validFromDate",
  validToDate: "validToDate",
  reasonCode: "reasonCode",
  stockTransferReasonCode: "stockTransferReasonCode",
  salesOrg: "salesOrg",
  departmentCode: "departmentCode",
  marketplaceUnit: "marketplaceUnit",
  channel: "channel",
  subChannel: "subChannel",
  accountType: "accountType",
  markFor: "markFor",
  businessType: "businessType",
  sourcingMethod: "sourcingMethod",
  shippingCondition: "shippingCondition",
  nameOfOrderer: "nameOfOrderer",
  deliveryBlock: "deliveryBlock",
  nikeInternalComments: "nikeInternalComments",
  addressOverride: "addressOverride",
  incorrectValue: "incorrectValue",
  exceptionType: "exceptionType",
  contractPurchaseOrderNumber: "contractPurchaseOrderNumber",
  distributionChannel: "distributionChannel",
  destinationCode: "destinationCode",
  internalOrderNumber: "internalOrderNumber",
  internalVendorNumber: "internalVendorNumber",
  materialSilhouette: "materialSilhouette",
  stoItemId: "stoItemId",
  vatRate: "vatRate",
  category: "category",
  lineItemNumber: "lineItemNumber",
  compOrderIndicator: "compOrderIndicator",
  deliveryGroupCode: "deliveryGroupCode",
  deliveryPriorityCode: "deliveryPriorityCode",
  deliveryStatus: "deliveryStatus",
  directShipPoNumber: "directShipPoNumber",
  directShipPoRequesitionNumber: "directShipPoRequesitionNumber",
  idpDate: "idpDate",
  idpBypass: "idpBypass",
  idpIndicator: "idpIndicator",
  launchCode: "launchCode",
  division: "division",
  materialNumber: "materialNumber",
  netAmount: "netAmount",
  netPrice: "netPrice",
  billingStatusCode: "billingStatusCode",
  splitDelivery: "splitDelivery",
  plantCode: "plantCode",
  season: "season",
  seasonYear: "seasonYear",
  productTheme: "productTheme",
  contractNumber: "contractNumber",
  contractLineItemNumber: "contractLineItemNumber",
  orderQuantity: "orderQuantity",
  requirementSegment: "requirementSegment",
  requirementSegmentCode: "requirementSegmentCode",
  iseg: "iseg",
  rejectionReasonCode: "rejectionReasonCode",
  itemCategory: "itemCategory",
  shortDescription: "shortDescription",
  storageLocationCode: "storageLocationCode",
  subCategory: "subCategory",
  usageCode: "usageCode",
  drsEligibilityIndicator: "drsEligibilityIndicator",
  wholesalePrice: "wholesalePrice",
  qualityCode: "qualityCode",
  msrQuantity: "msrQuantity",
  availability: "availability",
  confirmedDeliveryDate: "confirmedDeliveryDate",
  deliveryBlockCode: "deliveryBlockCode",
  deliveryQuantity: "deliveryQuantity",
  openQuantity: "openQuantity",
  salesUnitOfMeasure: "salesUnitOfMeasure",
  scheduleLineNumber: "scheduleLineNumber",
  orderItemQuantity: "orderItemQuantity",
  gtin: "gtin",
  size: "size",
  msrFlag: "msrFlag",
  msrp: "msrp",
  retailPrice: "retailPrice",
  vasChargeCode: "vasChargeCode",
  vasLongText: "vasLongText",
  vasMaterial: "vasMaterial",
  vasField1: "vasField1",
  vasField2: "vasField2",
  vasField3: "vasField3",
  vasTypeCode: "vasTypeCode",
  vatRegistrationCountryCode: "vatRegistrationCountryCode",
  vendorProductCode: "vendorProductCode",
  controlledAllocationIndicator: "controlledAllocationIndicator",
  materialItemGroup: "materialItemGroup",
  variantMaterial: "variantMaterial",
  buyersCatalogNumber: "buyersCatalogNumber",
  buyersSizeDescription: "buyersSizeDescription",
  buyersSize: "buyersSize",
  buyersStyleId: "buyersStyleId",
  materialCategory: "materialCategory",
  companyCode: "companyCode",
  deliveryUnit: "deliveryUnit",
  ediPurchaseOrderType: "ediPurchaseOrderType",
  genderAge: "genderAge",
  inactiveCustomer: "inactiveCustomer",
  vasIncrement: "vasIncrement",
  league: "league",
  markForStore: "markForStore",
  msrInnerPackQuantity: "msrInnerPackQuantity",
  materialDescription: "materialDescription",
  routeNumber: "routeNumber",
  salesOrderBlock: "salesOrderBlock",
  shippingConditionCode: "shippingConditionCode",
  supplierNumber: "supplierNumber",
  vendorStyleDescription: "vendorStyleDescription",
  proposedDeliveryDate: "proposedDeliveryDate",
  stockTransferVas1: "stockTransferVas1",
  stockTransferVas2: "stockTransferVas2",
  msrRatio: "msrRatio",
  leagueIndicator: "leagueIndicator",
  businessModelIndicator: "businessModelIndicator",
  gender: "gender",
  vasCode: "vasCode",
  dcBypassEligibility: "dcBypassEligibility",
  availabilityCheck: "availabilityCheck",
  managersName: "managersName",
  onBehalfOf: "onBehalfOf",
  forwardingAgent: "forwardingAgent",
  transportationZone: "transportationZone",
  name: "name",
  nameCO: "nameCO",
  streetNumberName: "streetNumberName",
  shopFloorSuiteEtc: "shopFloorSuiteEtc",
  postalCode: "postalCode",
  city: "city",
  country: "country",
  region: "region",
  district: "district",
  telephone: "telephone",
  preApprovalReferenceNumber: "preApprovalReferenceNumber",
  preApprovalSCIReferenceNumber: "preApprovalSCIReferenceNumber",
  businessPurpose: "businessPurpose",
  nameOfTheRecipient: "nameOfTheRecipient",
  businessPurposeDate: "businessPurposeDate",
  returnAuthorizationsNumber: "returnAuthorizationsNumber",
  numberOfCartons: "numberOfCartons",
  statusIndicator: "statusIndicator",
  productCodes: "productCodes",
  contractCreatedDate: "contractCreatedDate",
  availableQuantity: "availableQuantity",
  openToReference: "openToReference",
  total: "total",
  wrongfulValues: "wrongfulValues",
  referencedContractCheckPartner: "referencedContractCheckPartner",
  referencedContractSoldTo: "referencedContractSoldTo",
  purchaseOrderDocumentTypeCode: "purchaseOrderDocumentTypeCode",
  supplierNodeTypeCode: "supplierNodeTypeCode",
  unitAllotment: "unitAllotment",
  invoiceNumber: "invoiceNumber",
  shipmentTypeCode: "shipmentTypeCode",
  stoNumber: "stoNumber",
  stoLineItemNumber: "stoLineItemNumber",
  contractOwnerName: "contractOwnerName",
  stoType: "stoType",
};
