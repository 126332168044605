import actions from "./orderReprocess.actions.js";
import state from "./orderReprocess.state.js";
import mutations from "./orderReprocess.mutations.js";
import getters from "./orderReprocess.getters.js";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
