import { markRaw } from "vue";
import { ToggleButtonGroup, Select, TextField } from "@nike/now.podium-vue";

import BaseDatepicker from "@/components/ui/BaseDatepicker";

import { translateModule } from "@/utils/string/translate";

import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";

import { FormField, MaterialClassificationResults, FormInputResults } from "./types";

const translateOrderReprocess = translateModule("pages.orderReprocess");
const translateFields = translateModule("pages.orderReprocess.fields");

export const FIELD_KEYS = {
  ORDER_UUID: "orderId",
  PO_NUMBER: "poNumber",
  ORDER_TYPE: "orderType",
  STATUS: "status",
  ORDER_RECEIVED_DATE: "orderReceivedDate",
  PRESET: "preset",
  START_TIME: "createdOnAfter",
  END_TIME: "createdOnBefore",
} as const;

const orderReceivedDateOptions = [
  {
    value: "Preset",
    text: "Preset",
  },
  {
    value: "Custom Date Range",
    text: "Custom Date Range",
  },
];

const orderStatusOptions = [
  {
    value: "Failed in OFOA",
    text: "Failed in OFOA",
  },
  {
    value: "Failed in S4",
    text: "Failed in S4",
  },
];

const orderTypeOptions = [
  {
    value: "ZCON",
    text: "ZCON",
  },
  {
    value: "ZFRE",
    text: "ZFRE",
  },
  {
    value: "ZSTA",
    text: "ZSTA",
  },
  {
    value: "ZFRE",
    text: "ZFRE",
  },
  {
    value: "ZRST",
    text: "ZRST",
  },
  {
    value: "ZFIN",
    text: "ZFIN",
  },
  {
    value: "STO",
    text: "STO",
  },
  {
    value: "RSTO",
    text: "RSTO",
  },
  {
    value: "ZRFR",
    text: "ZRFR",
  },
  {
    value: "ZSYS",
    text: "ZSYS",
  },
  {
    value: "EDI",
    text: "EDI",
  },
];

const presetOptions = [
  {
    value: "15 Minutes",
    text: "15 Minutes",
  },
  {
    value: "30 Minutes",
    text: "30 Minutes",
  },
  {
    value: "1 Hour",
    text: "1 Hour",
  },
  {
    value: "4 Hours",
    text: "4 Hours",
  },
  {
    value: "8 Hours",
    text: "8 Hours",
  },
  {
    value: "12 Hours",
    text: "12 Hours",
  },
  {
    value: "24 Hours",
    text: "24 Hours",
  },
  {
    value: "Yesterday",
    text: "Yesterday",
  },
  {
    value: "Last 7 Days",
    text: "Last 7 Days",
  },
  {
    value: "Last 15 Days",
    text: "Last 15 Days",
  },
  {
    value: "Last 30 Days",
    text: "Last 30 Days",
  },
];

const optionsGetterForFormInputs = (results: FormInputResults) => {
  return results.map(({ id, alias }) => ({ value: id, text: alias }));
};

const headerFields: Array<FormField> = [
  {
    key: FIELD_KEYS.ORDER_UUID,
    component: markRaw(TextField),
    label: translateFields(FIELDS_ENUM.orderUuid),
    type: "both",
  },
  {
    key: FIELD_KEYS.PO_NUMBER,
    component: markRaw(TextField),
    label: translateFields(FIELDS_ENUM.poNumber),
    type: "both",
  },
  {
    key: FIELD_KEYS.STATUS,
    component: markRaw(Select),
    label: translateFields(FIELDS_ENUM.status),
    items: orderStatusOptions,
    type: "both",
  },
  {
    key: FIELD_KEYS.ORDER_TYPE,
    component: markRaw(Select),
    label: translateFields(FIELDS_ENUM.orderType),
    items: orderTypeOptions,
    type: "both",
  },
  {
    key: FIELD_KEYS.ORDER_RECEIVED_DATE,
    component: markRaw(Select),
    isSearchable: true,
    multiple: false,
    label: translateFields(FIELDS_ENUM.orderReceivedDate),
    emptyListText: translateOrderReprocess("startTypingToSeeOptions"),
    items: orderReceivedDateOptions,
    type: "both",
  },
  {
    key: FIELD_KEYS.PRESET,
    component: markRaw(Select),
    isSearchable: true,
    multiple: false,
    label: translateFields(FIELDS_ENUM.preset),
    emptyListText: translateOrderReprocess("startTypingToSeeOptions"),
    items: presetOptions,
    type: "Preset",
  },
  {
    key: FIELD_KEYS.START_TIME,
    component: markRaw(BaseDatepicker),
    label: translateFields(FIELDS_ENUM.startTime),
    isRangeDatepicker: false,
    type: "Custom Date Range",
  },
  {
    key: FIELD_KEYS.END_TIME,
    component: markRaw(BaseDatepicker),
    label: translateFields(FIELDS_ENUM.endTime),
    isRangeDatepicker: false,
    type: "Custom Date Range",
  },
  // {
  //   key: FIELD_KEYS.MARK_FOR,
  //   component: markRaw(TextField),
  //   iconRight: "SearchLargeDefaultOnLight",
  //   label: translateFields(FIELDS_ENUM.markFor),
  //   validation: {
  //     pattern: "^[0-9]{1,10}$",
  //     errorMessage: "Expected format: 1-10 digits",
  //   },
  // },
  // {
  //   key: FIELD_KEYS.SHIP_TO,
  //   component: markRaw(Select),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getShipTosForGlobalFilters",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.shipTo),
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.SOLD_TO,
  //   component: markRaw(Select),
  //   onInputAction: "globalFilters/fetchOptions",
  //   optionsEndpoint: "getSoldTosForGlobalFilters",
  //   emptyListText: translateGlobalFilters("startTypingToSeeOptions"),
  //   isSearchable: true,
  //   multiple: true,
  //   label: translateFields(FIELDS_ENUM.soldTo),
  //   optionsGetter: optionsGetterForFormInputs,
  // },
  // {
  //   key: FIELD_KEYS.SOURCE_SYSTEM,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.sourceSystem),
  //   multiple: true,
  // },
  // {
  //   key: FIELD_KEYS.SUB_CHANNEL,
  //   component: markRaw(Select),
  //   componentType: "select",
  //   label: translateFields(FIELDS_ENUM.subChannel),
  //   multiple: true,
  // },
];

// const lineFields: Array<FormField> = [
//   {
//     key: FIELD_KEYS.LINE_CANCEL_DATE,
//     component: markRaw(BaseDatepicker),
//     label: translateFields(FIELDS_ENUM.cancelDateLineLevel),
//     isRangeDatepicker: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.category),
//     multiple: true,
//     isSearchable: true,
//     onInputAction: 'globalFilters/fetchOptions',
//     optionsEndpoint: 'fetchMaterialCategory',
//     emptyListText: translateGlobalFilters('startTypingToSeeOptions'),
//     optionsGetter: optionsGetterForMaterialClassification,
//   },
//   {
//     key: FIELD_KEYS.LINE_CRD,
//     component: markRaw(BaseDatepicker),
//     label: translateFields(FIELDS_ENUM.crdLineLevel),
//     isRangeDatepicker: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_DC_BYPASS_ELIGIBILITY,
//     component: markRaw(ToggleButtonGroup),
//     label: translateFields(FIELDS_ENUM.dcBypassEligibility),
//     items: [
//       {
//         text: translateGlobalFilters('all'),
//         value: undefined,
//       },
//       {
//         text: translateGlobalFilters('eligible'),
//         value: LINE_DC_BYPASS_ELIGIBILITY_VALUES.ELIGIBLE,
//       },
//       {
//         text: translateGlobalFilters('notEligible'),
//         value: LINE_DC_BYPASS_ELIGIBILITY_VALUES.NOT_ELIGIBLE,
//       },
//     ],
//   },
//   {
//     key: FIELD_KEYS.LINE_DIVISION,
//     component: markRaw(Select),
//     componentType: 'select',
//     label: translateFields(FIELDS_ENUM.division),
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_GENDER,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.gender),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_ITEM_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.itemCategory),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_KBI_INDICATOR,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.launchCode),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_LEAGUE_INDICATOR,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.leagueIndicator),
//     isSearchable: true,
//     multiple: true,
//   },
//   {
//     key: FIELD_KEYS.LINE_MATERIAL_NUMBER,
//     component: markRaw(TextField),
//     iconRight: 'SearchLargeDefaultOnLight',
//     label: translateFields(FIELDS_ENUM.materialNumber),
//     validation: {
//       pattern: '^([a-zA-Z0-9]{6})-([a-zA-Z0-9]{3})$',
//       errorMessage: 'Expected Format: BQ3757-101, SX7676-100',
//     },
//   },
//   {
//     key: FIELD_KEYS.LINE_PLANT_CODE,
//     component: markRaw(TextField),
//     iconRight: 'SearchLargeDefaultOnLight',
//     label: translateFields(FIELDS_ENUM.plantCode),
//   },
//   {
//     key: FIELD_KEYS.LINE_REQUIREMENT_SEGMENT,
//     component: markRaw(Select),
//     componentType: 'select',
//     multiple: true,
//     label: translateFields(FIELDS_ENUM.requirementSegment),
//   },
//   {
//     key: FIELD_KEYS.LINE_SUB_CATEGORY,
//     component: markRaw(Select),
//     label: translateFields(FIELDS_ENUM.subCategory),
//     multiple: true,
//     isSearchable: true,
//     onInputAction: 'globalFilters/fetchOptions',
//     optionsEndpoint: 'fetchMaterialSubCategory',
//     emptyListText: translateGlobalFilters('startTypingToSeeOptions'),
//     optionsGetter: optionsGetterForMaterialClassification,
//   },
//   {
//     key: FIELD_KEYS.LINE_VAS,
//     component: markRaw(ToggleButtonGroup),
//     label: translateGlobalFilters('vas'),
//     items: [
//       {
//         text: translateGlobalFilters('all'),
//         value: undefined,
//       },
//       {
//         text: translateGlobalFilters('withVas'),
//         value: VAS_VALUES.WITH_WAS,
//       },
//       {
//         text: translateGlobalFilters('noVas'),
//         value: VAS_VALUES.NO_VAS,
//       },
//     ],
//   },
// ];

// const adjustedLineFields = lineFields.map(field => ({ ...field, isLineItem: true }));

export const FIELDS = [...headerFields];
