import { get, isEqual, isEmpty } from "lodash";
import employeeRepository from "@/api/now/employee";
import auditEventRepository from "@/api/auditEvent";
import filterPanelRepository from "@/api/now/caseManagement/filterPanel";
import { FIELDS } from "@/pages/AuditEvent/Filters/config.ts";

import { updateFieldsItems, validateField } from "./helpers";
import mutations from "./mutation-type";

const myOrdersTable = {
  setGridApi({ commit }, gridApi) {
    commit(mutations.SET_GRID_API, gridApi);
  },

  refreshOrdersDataSource({ dispatch, commit, state }) {
    commit(mutations.SET_MY_ORDERS_ERRORS, {});

    dispatch("createOrdersTableDatasource");

    if (state.gridApi) {
      state.gridApi.setServerSideDatasource(state.datasource);
    }
  },

  refreshOrderEventsDataSource({ dispatch, commit, state }) {
    commit(mutations.SET_MY_ORDERS_ERRORS, {});

    dispatch("createOrderEventsTableDatasource");

    if (state.gridApi) {
      state.gridApi.setServerSideDatasource(state.datasource);
    }
  },

  createTableColumns({ commit, dispatch }) {
    const editHandler = ({ data: orderData } = {}) => {
      if (orderData && orderData.nowId) {
        router.push(`/myOrders/${orderData.nowId}`);
      }
    };

    const deleteActionHandler = ({ data: orderData } = {}) => {
      if (orderData && orderData.nowId) {
        dispatch("setOrderId", orderData.nowId);
      }

      dispatch("modal/onOpenModal", "applyActionModal", { root: true });
    };

    const errorDetailActionHandler = ({ data: orderData, rowIndex } = {}) => {
      const errorRef = orderData?.errorMessageReference;
      const message = orderData?.message;

      dispatch("fetchMyOrdersError", {
        errorRef,
        rowIndex,
        message,
      });

      dispatch("modal/onOpenModal", "myOrdersErrorModal", { root: true });
    };

    const resolveActionHandler = ({ data: orderData } = {}) => {
      redirectToCmOrderException(orderData.poNumber);
    };

    const resubmitActionHandler = ({ data: orderData } = {}) => {
      if (orderData && orderData.nowId) {
        dispatch("resubmitOrder", orderData.nowId);
      }
    };

    const { columnsDefinition, actionsRendererConfig } = constants;

    const actionsConfig = {
      ...actionsRendererConfig,
      cellStyle: {
        "justify-content": "center",
      },

      cellRenderer: null,

      cellRendererSelector: (params) => {
        const orderStatus = params?.data?.status?.replaceAll(" ", "_").toUpperCase();

        const defaultParams = {
          component: "actionsRenderer",
          params: {
            menuOptions: [],
          },
        };

        const statusesAllowedToBeDeleted = [
          orderStatuses.DRAFT,
          orderStatuses.REJECTED,
          orderStatuses.SENT_FAILED,
          orderStatuses.CONTAINS_ERRORS,
          orderStatuses.PENDING_APPROVAL,
          orderStatuses.REWORK,
        ];

        if (
          [orderStatuses.DRAFT, orderStatuses.REWORK, orderStatuses.CONTAINS_ERRORS].includes(
            orderStatus
          )
        ) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t("pages.myOrders.edit"),
            handler: editHandler,
          });
        }

        if (orderStatus === orderStatuses.SENT_FAILED) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t("pages.myOrders.resubmit"),
            handler: resubmitActionHandler,
          });
        }

        if (statusesAllowedToBeDeleted.includes(orderStatus)) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t("pages.myOrders.deleteOrder"),
            handler: deleteActionHandler,
          });
        }

        if (orderStatus === orderStatuses.FAILED_IN_S4) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t("pages.myOrders.errorDetail"),
            handler: errorDetailActionHandler,
          });
        }

        if (orderStatus === orderStatuses.FAILED_IN_SCP) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t("pages.myOrders.errorDetail"),
            handler: errorDetailActionHandler,
          });
        }

        if (orderStatus === orderStatuses.EXCEPTION) {
          defaultParams.params.menuOptions.push({
            name: i18n.global.t("pages.myOrders.resolve"),
            handler: resolveActionHandler,
          });
        }

        return defaultParams;
      },
    };

    commit(mutations.SET_COLUMN_DEF, [...columnsDefinition, actionsConfig]);
  },

  createOrdersTableDatasource({ dispatch, commit, getters, state }) {
    const datasource = {
      getRows: async (rowsCreationParams) => {
        dispatch("handleSortingOrder", rowsCreationParams);

        await dispatch("handleFiltersModel", rowsCreationParams);

        await dispatch("fetchOrders");

        rowsCreationParams.successCallback(getters.getFormatedOrdersData, state.orders.length);

        if (!state.orders.length) {
          rowsCreationParams.api.showNoRowsOverlay();
        }
      },
    };

    commit("SET_DATA_SOURCE", datasource);
  },

  createOrderEventsTableDatasource({ dispatch, commit, getters, state }) {
    const datasource = {
      getRows: async (rowsCreationParams) => {
        // dispatch("handleSortingOrder", rowsCreationParams);

        await dispatch("handleFiltersModel", rowsCreationParams);

        await dispatch("fetchOrderEvent");

        rowsCreationParams.successCallback(
          getters.getFormatedOrderEventsData,
          state.orderEvents.length
        );

        if (!state.orderEvents.length) {
          rowsCreationParams.api.showNoRowsOverlay();
        }
      },
    };

    commit("SET_DATA_SOURCE", datasource);
  },

  async handleSortingOrder({ dispatch, state }, rowsCreationParams) {
    // const { colId = "createdAt", sort = "desc" } = get(
    //   rowsCreationParams,
    //   "request.sortModel[0]",
    //   {}
    // );
    // const sortedColumnName = constants.columnNames[colId];
    // const sortedColumnOrder = constants.sortedColumnOrders[sort];
    // if (
    //   state.sortedColumnName !== sortedColumnName ||
    //   state.sortedColumnOrder !== sortedColumnOrder
    // ) {
    //   await dispatch(RESET_PAGE_NUMBER);
    // }
    // await dispatch(SET_SORTED_COLUMN_NAME, sortedColumnName);
    // await dispatch(SET_SORTED_COLUMN_ORDER, sortedColumnOrder);
  },

  async handleFiltersModel({ state, dispatch }, rowsCreationParams) {
    const filterModel = rowsCreationParams?.request?.filterModel;

    if (!isEmpty(filterModel) && !isFiltersValid(filterModel)) {
      return;
    }

    if (!isEqual(state.tableFilters, filterModel)) {
      await dispatch(RESET_PAGE_NUMBER);
    }

    // await dispatch(SET_TABLE_FILTERS, filterModel);
  },

  async deleteOrderHandler({ dispatch }) {
    await dispatch("deleteOrder");
    await dispatch("fetchMyOrdersSummary");
    await dispatch("refreshDataSource");
  },

  setTableParams({ commit }, params) {
    commit(mutations.SET_TABLE_PARAMS, params);
  },

  setOrderId({ commit }, orderId) {
    commit(mutations.SET_ORDER_ID, orderId);
  },

  setPageSize({ commit }, pageSize) {
    commit(mutations.SET_PAGE_SIZE, pageSize);
  },

  setSortedColumnName({ commit }, sortedColumnName) {
    commit(mutations.SET_SORTED_COLUMN_NAME, sortedColumnName);
  },

  setSortedColumnOrder({ commit }, sortedColumnOrder) {
    commit(mutations.SET_SORTED_COLUMN_ORDER, sortedColumnOrder);
  },

  // setTableFilters({ commit }, tableFilters) {
  //   commit(mutations.SET_TABLE_FILTERS, tableFilters);
  // },

  setPageNumber({ commit }, pageNumber) {
    commit(mutations.SET_PAGE_NUMBER, pageNumber);
  },

  resetPageNumber({ commit }) {
    commit(mutations.SET_PAGE_NUMBER, 0);
    commit(mutations.RESET_PAGE_TOKEN);
  },
};

export default {
  createFilterForm({ state, commit, dispatch }) {
    if (state.filterForm?.size) {
      return;
    }

    const filterForm = new Map();

    FIELDS.forEach((field) => {
      filterForm.set(field.key, {
        ...field,
        exclude: false,
      });
    });

    commit("SET_FILTER_FORM", filterForm);
    dispatch("getInitialFilterOptions");
  },

  async getInitialFilterOptions({ commit, dispatch, state, rootState }) {
    const updatedFilterForm = new Map(state.filterForm);

    updateFieldsItems({
      filterForm: updatedFilterForm,
      geos: rootState.user.geo,
    });

    try {
      const { data } = await filterPanelRepository.fetchGlobalFilterParams();

      updateFieldsItems({
        filterForm: updatedFilterForm,
        geos: rootState.user.geo,
      });

      commit("SET_FILTER_FORM", updatedFilterForm);

      await dispatch("fetchSavedFilters");

      dispatch("applyFilters");
    } catch (e) {
      console.error(e);
    }
  },

  updateFilterField({ commit, state, dispatch }, field) {
    const updatedField = { ...field };

    if (updatedField.value === undefined) {
      updatedField.exclude = false;
      updatedField.errorMessage = "";

      commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
    }

    if (updatedField?.value?.length && updatedField.validation) {
      const errorMessage = validateField(updatedField);

      updatedField.errorMessage = errorMessage;

      if (errorMessage) {
        commit("ADD_FIELD_WITH_ERROR", updatedField.label);
      } else {
        commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
      }
    }

    commit("SET_FILTER_FIELD", updatedField);

    if (updatedField.dependencies) {
      const { value } = updatedField;

      field.dependencies.forEach(({ field, handler }) => {
        const fieldToUpdate = handler(state.filterForm.get(field), value);

        dispatch("updateFilterField", fieldToUpdate);
      });
    }

    commit("SET_IS_FORM_EDITED", true);
  },

  async fetchOptions({ commit, state }, params) {
    const { key, value } = params;

    const filterField = { ...state.filterForm.get(key) };

    if (!value) {
      filterField.items = [];
      commit("SET_FILTER_FIELD", filterField);

      return;
    }

    try {
      commit("SET_FILTER_FIELD", {
        ...filterField,
        isLoading: true,
        items: [],
      });

      const { data } = await filterPanelRepository[filterField.optionsEndpoint]({ query: value });

      filterField.items = filterField?.optionsGetter(data.results) || [];
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_FILTER_FIELD", { ...filterField, isLoading: false });
    }
  },

  async fetchUser({ commit, state }, field) {
    const userField = { ...state.filterForm.get(field.key) };
    if (!field.value) {
      userField.items = [];
      commit("SET_FILTER_FIELD", userField);

      return;
    }

    commit("SET_FILTER_FIELD", {
      ...userField,
      isLoading: true,
      items: [],
    });

    try {
      const { data } = await employeeRepository.getEmployeeByQuery(field.value);

      userField.items = data.map(({ nikeId, email }) => ({ value: nikeId, text: email }));
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_FILTER_FIELD", { ...userField, isLoading: false });
    }
  },

  updateFilters({ commit }, updatedFields) {
    commit("SET_APPLY_FILTER_FIELDS", updatedFields);
  },

  clearSingleFilter({ state, commit, dispatch }, fieldKey) {
    const filteredFields = state.appliedFilterFields.filter(({ key }) => key !== fieldKey);

    const filteredFormField = { ...state.filterForm.get(fieldKey) };

    const updatedField = {
      ...filteredFormField,
      value: undefined,
      exclude: false,
    };

    commit("SET_FILTER_FIELD", updatedField);
    commit("SET_IS_FORM_EDITED", true);

    dispatch("updateFilters", filteredFields);
  },

  clearFilters({ state, commit, dispatch }) {
    state.filterForm.forEach((field) => {
      const updatedField = {
        ...field,
        value: undefined,
        exclude: false,
        errorMessage: "",
      };

      commit("SET_FILTER_FIELD", updatedField);
    });

    commit("RESET_FIELD_ERRORS");
    commit("SET_SELECTED_FILTERS_NAME", "");
    dispatch("updateFilters", []);

    commit("SET_IS_FORM_EDITED", true);
  },

  applyFilters({ state, dispatch }) {
    const filterFields = [];

    state.filterForm.forEach(({ key, label, value, exclude }) => {
      if (value) {
        const isDatePicker = FIELDS.find(
          (configField) => configField.key === key
        ).isRangeDatepicker;
        const mergeSign = isDatePicker ? " - " : ", ";
        const newValue = Array.isArray(value) ? value.join(mergeSign) : value;

        filterFields.push({
          key,
          exclude,
          name: label,
          value: newValue,
        });
      }
    });

    dispatch("updateFilters", filterFields);
  },

  setSearchQuery({ commit }, searchQuery) {
    commit("SET_SEARCH_QUERY", searchQuery);
  },

  setSearchType({ commit }, searchType) {
    commit("SET_SEARCH_TYPE", searchType);
  },

  async fetchOrders({ state, commit, dispatch, rootState }) {
    try {
      const requestFilters = {};
      const filters = state.appliedFilterFields || [];

      filters.forEach((param) => {
        requestFilters[param.key] = param.value;
      });

      const searchQuery = rootState.globalFilters.searchQuery;
      if (searchQuery !== "") {
        requestFilters["orderIds"] = searchQuery;
      }

      commit("SET_IS_ORDERS_INFO_LOADING", true);

      if (requestFilters["orderIds"]) {
        const { data, warnings } = await auditEventRepository.fetchOrderEvent(
          requestFilters["orderIds"],
          requestFilters
        );

        const isFetchFailed =
          warnings?.length > 0 && Object.values(data).every((value) => value === "0");

        if (isFetchFailed) {
          const errorMessage = i18n.global.t(
            "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
          );
          notificationUtils.showErrorBanner({ title: errorMessage });
        }
        commit("SET_ORDERS", data?.objects[0] || []);
        commit("SET_ORDER_EVENTS", data?.objects || []);

        commit(mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER, data?.pages?.totalResources);
        commit(mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER, data?.pages?.totalPages);
        commit("SET_PAGE_TOKEN", 0);
      }
    } catch (e) {
      commit("SET_ORDERS", []);
      commit("SET_ORDER_EVENTS", []);

      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_INFO_LOADING", false);
    }
  },

  async fetchOrderEvent({ state, commit, dispatch, rootState }) {
    try {
      const requestFilters = {};
      const filters = state.appliedFilterFields || [];

      filters.forEach((param) => {
        requestFilters[param.key] = param.value;
      });

      const searchQuery = rootState.globalFilters.searchQuery;
      if (searchQuery !== "") {
        requestFilters["orderIds"] = searchQuery;
      }

      commit("SET_IS_ORDERS_INFO_LOADING", true);

      if (requestFilters["orderIds"]) {
        const { data, warnings } = await auditEventRepository.fetchOrderEvent(
          requestFilters["orderIds"],
          requestFilters
        );

        const isFetchFailed =
          warnings?.length > 0 && Object.values(data).every((value) => value === "0");

        if (isFetchFailed) {
          const errorMessage = i18n.global.t(
            "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
          );
          notificationUtils.showErrorBanner({ title: errorMessage });
        }

        commit("SET_ORDERS", data?.objects[0] || []);
        commit("SET_ORDER_EVENTS", data?.objects || []);

        commit(mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER, data?.pages?.totalResources);
        commit(mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER, data?.pages?.totalPages);
        commit("SET_PAGE_TOKEN", 0);
      }
    } catch (e) {
      commit("SET_ORDERS", []);
      commit("SET_ORDER_EVENTS", []);

      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_INFO_LOADING", false);
    }
  },

  ...myOrdersTable,
};
