import { get, isEqual, isEmpty } from "lodash";
import orderReprocessRepository from "@/api/orderReprocess";
import filterPanelRepository from "@/api/now/caseManagement/filterPanel";
import { FIELDS, FIELD_KEYS } from "@/pages/OrderReprocess/Filters/config.ts";
import { showSuccessBanner, showErrorBanner } from "@/utils/notifications";
import { translateModule } from "@/utils/string/translate";
import { updateFieldsItems, validateField, getDateRange, validateRequiredFields } from "./helpers";
import mutations from "./mutation-type";
import { duration } from "moment";

const myOrdersTable = {
  setGridApi({ commit }, gridApi) {
    commit(mutations.SET_GRID_API, gridApi);
  },

  refreshOrdersDataSource({ dispatch, commit, state }) {
    dispatch("createOrdersTableDatasource");

    if (state.gridApi) {
      state.gridApi.setServerSideDatasource(state.datasource);
    }
  },

  createOrdersTableDatasource({ dispatch, commit, getters, state }) {
    const datasource = {
      getRows: async (rowsCreationParams) => {
        // dispatch("handleSortingOrder", rowsCreationParams);

        // await dispatch("handleFiltersModel", rowsCreationParams);
        await dispatch("fetchOrders");

        rowsCreationParams.successCallback(getters.getFormatedOrdersData, state.orders.length);

        if (!state.orders.length) {
          rowsCreationParams.api.showNoRowsOverlay();
        }
      },
    };

    commit("SET_DATA_SOURCE", datasource);
  },

  async handleSortingOrder({ dispatch, state }, rowsCreationParams) {
    // const { colId = "createdAt", sort = "desc" } = get(
    //   rowsCreationParams,
    //   "request.sortModel[0]",
    //   {}
    // );
    // const sortedColumnName = constants.columnNames[colId];
    // const sortedColumnOrder = constants.sortedColumnOrders[sort];
    // if (
    //   state.sortedColumnName !== sortedColumnName ||
    //   state.sortedColumnOrder !== sortedColumnOrder
    // ) {
    //   await dispatch(RESET_PAGE_NUMBER);
    // }
    // await dispatch(SET_SORTED_COLUMN_NAME, sortedColumnName);
    // await dispatch(SET_SORTED_COLUMN_ORDER, sortedColumnOrder);
  },

  async handleFiltersModel({ state, dispatch }, rowsCreationParams) {
    const filterModel = rowsCreationParams?.request?.filterModel;

    if (!isEmpty(filterModel) && !isFiltersValid(filterModel)) {
      return;
    }

    if (!isEqual(state.tableFilters, filterModel)) {
      await dispatch(RESET_PAGE_NUMBER);
    }

    // await dispatch(SET_TABLE_FILTERS, filterModel);
  },

  async deleteOrderHandler({ dispatch }) {
    await dispatch("deleteOrder");
    await dispatch("fetchMyOrdersSummary");
    await dispatch("refreshDataSource");
  },

  setTableParams({ commit }, params) {
    commit(mutations.SET_TABLE_PARAMS, params);
  },

  setOrderId({ commit }, orderId) {
    commit(mutations.SET_ORDER_ID, orderId);
  },

  setPageSize({ commit }, pageSize) {
    commit(mutations.SET_PAGE_SIZE, pageSize);
  },

  setSortedColumnName({ commit }, sortedColumnName) {
    commit(mutations.SET_SORTED_COLUMN_NAME, sortedColumnName);
  },

  setSortedColumnOrder({ commit }, sortedColumnOrder) {
    commit(mutations.SET_SORTED_COLUMN_ORDER, sortedColumnOrder);
  },

  // setTableFilters({ commit }, tableFilters) {
  //   commit(mutations.SET_TABLE_FILTERS, tableFilters);
  // },

  setPageNumber({ commit }, pageNumber) {
    commit(mutations.SET_PAGE_NUMBER, pageNumber);
  },

  resetPageNumber({ commit }) {
    commit(mutations.SET_PAGE_NUMBER, 0);
    commit(mutations.RESET_PAGE_TOKEN);
  },
};

export default {
  createFilterForm({ state, commit, dispatch }) {
    if (state.filterForm?.size) {
      return;
    }

    const filterForm = new Map();

    FIELDS.forEach((field) => {
      filterForm.set(field.key, {
        ...field,
        exclude: false,
      });
    });

    commit("SET_FILTER_FORM", filterForm);
    dispatch("getInitialFilterOptions");
  },

  async getInitialFilterOptions({ commit, dispatch, state, rootState }) {
    const updatedFilterForm = new Map(state.filterForm);

    try {
      updateFieldsItems({
        filterForm: updatedFilterForm,
        geos: rootState.user.geo,
      });

      commit("SET_FILTER_FORM", updatedFilterForm);

      dispatch("applyFilters");
    } catch (e) {
      console.error(e);
    }
  },

  updateFilterField({ commit, state, dispatch }, field) {
    const updatedField = { ...field };

    if (updatedField.value === undefined) {
      updatedField.exclude = false;
      updatedField.errorMessage = "";

      commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
    }

    if (updatedField?.value?.length && updatedField.validation) {
      const errorMessage = validateField(updatedField);

      updatedField.errorMessage = errorMessage;

      if (errorMessage) {
        commit("ADD_FIELD_WITH_ERROR", updatedField.label);
      } else {
        commit("REMOVE_FIELD_WITH_ERROR", updatedField.label);
      }
    }

    commit("SET_FILTER_FIELD", updatedField);

    if (updatedField.dependencies) {
      const { value } = updatedField;

      field.dependencies.forEach(({ field, handler }) => {
        const fieldToUpdate = handler(state.filterForm.get(field), value);

        dispatch("updateFilterField", fieldToUpdate);
      });
    }

    commit("SET_IS_FORM_EDITED", true);
  },

  async fetchOptions({ commit, state }, params) {
    const { key, value } = params;

    const filterField = { ...state.filterForm.get(key) };

    if (!value) {
      filterField.items = [];
      commit("SET_FILTER_FIELD", filterField);

      return;
    }

    try {
      commit("SET_FILTER_FIELD", {
        ...filterField,
        isLoading: true,
        items: [],
      });

      const { data } = await filterPanelRepository[filterField.optionsEndpoint]({ query: value });

      filterField.items = filterField?.optionsGetter(data.results) || [];
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_FILTER_FIELD", { ...filterField, isLoading: false });
    }
  },

  updateFilters({ commit }, updatedFields) {
    commit("SET_APPLY_FILTER_FIELDS", updatedFields);
  },

  clearSingleFilter({ state, commit, dispatch }, fieldKey) {
    const filteredFields = state.appliedFilterFields.filter(({ key }) => key !== fieldKey);

    const filteredFormField = { ...state.filterForm.get(fieldKey) };

    const updatedField = {
      ...filteredFormField,
      value: undefined,
      exclude: false,
    };

    commit("SET_FILTER_FIELD", updatedField);
    commit("SET_IS_FORM_EDITED", true);

    dispatch("updateFilters", filteredFields);
  },

  clearFilters({ state, commit, dispatch }) {
    state.filterForm.forEach((field) => {
      const updatedField = {
        ...field,
        value: undefined,
        exclude: false,
        errorMessage: "",
      };

      commit("SET_FILTER_FIELD", updatedField);
    });

    commit("RESET_FIELD_ERRORS");
    commit("SET_SELECTED_FILTERS_NAME", "");
    dispatch("updateFilters", []);

    commit("SET_IS_FORM_EDITED", true);
  },

  applyFilters({ state, dispatch }) {
    const filterFields = [];

    state.filterForm.forEach(({ key, label, value, exclude }) => {
      if (value) {
        const isDatePicker = FIELDS.find(
          (configField) => configField.key === key
        ).isRangeDatepicker;
        const mergeSign = isDatePicker ? " - " : ", ";
        const newValue = Array.isArray(value) ? value.join(mergeSign) : value;

        filterFields.push({
          key,
          exclude,
          name: label,
          value: newValue,
        });
      }
    });

    dispatch("updateFilters", filterFields);
  },

  setSearchQuery({ commit }, searchQuery) {
    commit("SET_SEARCH_QUERY", searchQuery);
  },

  setSearchType({ commit }, searchType) {
    commit("SET_SEARCH_TYPE", searchType);
  },

  async fetchOrders({ state, commit }) {
    try {
      var requestFilters = new URLSearchParams();
      const filters = state.appliedFilterFields || [];

      if (!validateRequiredFields(filters)) {
        const translateNotifications = translateModule("notifications");
        throw new Error(translateNotifications("errorMessages.http.reprocessRequiredFields"));
      }

      filters.forEach((param) => {
        if (
          ![
            FIELD_KEYS.ORDER_RECEIVED_DATE,
            FIELD_KEYS.PRESET,
            FIELD_KEYS.START_TIME,
            FIELD_KEYS.END_TIME,
          ].includes(param.key)
        ) {
          requestFilters.append("filter", `${param.key}(${param.value})`);
        }
      });

      const datesRange = getDateRange(filters);
      if (datesRange.createdOnAfter && datesRange.createdOnBefore) {
        requestFilters.append("filter", `${FIELD_KEYS.START_TIME}(${datesRange.createdOnAfter})`);
        requestFilters.append("filter", `${FIELD_KEYS.END_TIME}(${datesRange.createdOnBefore})`);
      }

      requestFilters.append("anchor", state.pageNumber);
      requestFilters.append("count", state.pageSize);

      commit("SET_IS_ORDERS_INFO_LOADING", true);

      const { data, warnings } = await orderReprocessRepository.fetchOrders(requestFilters);

      const isFetchFailed =
        warnings?.length > 0 && Object.values(data).every((value) => value === "0");

      if (isFetchFailed) {
        const errorMessage = i18n.global.t(
          "notifications.errorMessages.http.unableToGetOrderDataSummarySupport"
        );
        notifications.showErrorBanner({ title: errorMessage });
      }
      commit("SET_ORDERS", data?.orderCollection || []);
      // commit("SET_ORDER_EVENTS", data?.objects || []);

      commit(mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER, data?.count || 0);
      commit(mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER, data?.pageTotal || 0);
      commit("SET_PAGE_TOKEN", 0);
    } catch (e) {
      showErrorBanner({
        title: e.errorMessage || e.message || e,
        duration: 5000,
      });
      commit("SET_ORDERS", []);
      // commit("SET_ORDER_EVENTS", []);

      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_INFO_LOADING", false);
    }
  },

  async reprocessSelectedOrders({ state, commit, dispatch, rootState }) {
    try {
      const requestParams = {
        orderIds: state.selectedOrders,
        userId: rootState.user.user.nikeId,
        defectId: state.commentModal.defect,
        comment: state.commentModal.comment,
      };

      commit("SET_IS_ORDERS_INFO_LOADING", true);

      const { data, warnings } = await orderReprocessRepository.reprocessOrders(requestParams);

      if (data.status === "COMPLETE") {
        const translateNotifications = translateModule("notifications");
        showSuccessBanner({
          title: translateNotifications("sucessMessages.orderReprocessed"),
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_IS_ORDERS_INFO_LOADING", false);
    }
  },

  setSelectedOrder({ commit }, orders) {
    commit("SET_SELECTED_ORDER", orders);
  },

  updateCommentField({ state, commit }, modalField) {
    commit("SET_COMMENT_MODAL_FIELD", { ...state.commentModal, ...modalField });
  },

  resetSelectedOrders({ state, commit }) {
    commit("RESET_COMMENT_MODAL_FIELDS");
  },
  ...myOrdersTable,
};
