import pagesURL from "./routes";

import LoginCallback from "./LoginCallback.vue";

const AuditEventOrders = () =>
  import(/* webpackChunkName: "AuditEventOrders" */ "@/pages/AuditEvent/Orders.vue");
const AuditEventOrderEvents = () =>
  import(/* webpackChunkName: "AuditEventOrderEvents" */ "@/pages/AuditEvent/OrderEvents.vue");
const OrderReprocess = () =>
  import(/* webpackChunkName: "OrderReprocess" */ "@/pages/OrderReprocess/OrderReprocess.vue");
const NotAllowed = () => import(/* webpackChunkName: "NotAllowed" */ "@/pages/NotAllowed");
const SessionExpired = () =>
  import(/* webpackChunkName: "SessionExpired" */ "@/pages/SessionExpired");
const ServiceDown = () => import(/* webpackChunkName: "ServiceDown" */ "@/pages/ServiceDown");
const PlannedOutage = () => import(/* webpackChunkName: "PlannedOutage" */ "@/pages/PlannedOutage");
const StubPageControls = () =>
  import(/* webpackChunkName: "StubPageControls" */ "@/pages/StubPageControls/StubPageControls");

const routes = [
  {
    path: pagesURL.ROOT,
    name: "HOME",
    meta: {
      requiresAuth: true,
      hasSearch: false,
    },
    component: OrderReprocess,
  },
  {
    path: pagesURL.CALLBACK,
    name: "Callback",
    component: LoginCallback,
  },
  {
    path: pagesURL.NOT_ALLOWED,
    name: "NotAllowed",
    component: NotAllowed,
    props: true,
  },
  {
    path: pagesURL.SESSION_EXPIRED,
    name: "SessionExpired",
    meta: { requiresUnauth: true },
    component: SessionExpired,
  },
  {
    path: pagesURL.SERVICE_DOWN,
    name: "ServiceDown",
    meta: { isMaintenance: true, requiresAuth: true },
    component: ServiceDown,
  },
  {
    path: pagesURL.PLANNED_OUTAGE,
    name: "PlannedOutage",
    meta: { isMaintenance: true, requiresAuth: true },
    component: PlannedOutage,
  },
  {
    path: pagesURL.STUB_PAGE_CONTROLS,
    name: "StubPageControls",
    meta: { isMaintenance: true, requiresAuth: true },
    component: StubPageControls,
  },
  {
    path: pagesURL.ORDER_REPROCESS,
    name: "OrderReprocess",
    meta: { requiresAuth: true },
    component: OrderReprocess,
  },
  {
    path: pagesURL.AUDIT_EVENT.BASE,
    meta: { requiresAuth: true },
    children: [
      {
        path: pagesURL.AUDIT_EVENT.ORDERS,
        name: "AuditEventOrders",
        meta: {
          requiresAuth: true,
          hasSearch: false,
        },
        component: AuditEventOrders,
      },
      {
        path: `${pagesURL.AUDIT_EVENT.EVENTS}/:orderId`,
        name: "AuditEventOrderEvents",
        meta: { requiresAuth: true },
        component: AuditEventOrderEvents,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "OrderReprocess" },
  },
];

export default routes;
