import mutations from "./mutation-type";

const myOrdersTable = {
  [mutations.SET_GRID_API](state, gridApi) {
    state.gridApi = gridApi;
  },

  [mutations.SET_DATA_SOURCE](state, datasource) {
    state.datasource = datasource;
  },

  [mutations.SET_MY_ORDERS_ERRORS](state, myOrdersErrors) {
    state.myOrdersErrors = myOrdersErrors;
  },

  [mutations.SET_COLUMN_DEF](state, columnDef) {
    state.columnDef = columnDef;
  },

  [mutations.SET_TABLE_FILTERS](state, tableFilters) {
    state.tableFilters = tableFilters;
  },

  [mutations.SET_TABLE_PARAMS](state, tableParams) {
    state.tableParams = tableParams;
  },

  [mutations.SET_ORDER_ID](state, orderId) {
    state.orderId = orderId;
  },

  [mutations.SET_PAGE_SIZE](state, pageSize) {
    state.pageSize = pageSize;
  },

  [mutations.SET_PAGE_NUMBER](state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  [mutations.SET_PAGE_TOKEN](state, { pageNumber, token }) {
    state.pageTokens[pageNumber] = token;
  },

  [mutations.RESET_PAGE_TOKEN](state) {
    // first page always has a `null` token
    state.pageTokens = [null];
  },

  [mutations.SET_SORTED_COLUMN_NAME](state, sortedColumnName) {
    state.sortedColumnName = sortedColumnName;
  },

  [mutations.SET_SORTED_COLUMN_ORDER](state, sortedColumnOrder) {
    state.sortedColumnOrder = sortedColumnOrder;
  },

  [mutations.SET_TOTAL_AVAILABLE_ORDERS_NUMBER](state, totalAvailableOrdersNumber) {
    state.totalAvailableOrdersNumber = totalAvailableOrdersNumber;
  },

  [mutations.SET_TOTAL_AVAILABLE_PAGES_NUMBER](state, totalAvailablePagesNumber) {
    state.totalAvailablePagesNumber = totalAvailablePagesNumber;
  },

  [mutations.SET_USER_PREFERENCES](state, userPreferences) {
    state.userPreferences = userPreferences;
  },
};

export default {
  SET_FILTER_FORM(state, form) {
    state.filterForm = form;
  },

  SET_FILTER_FIELD(state, field) {
    state.filterForm.set(field.key, field);
  },

  SET_APPLY_FILTER_FIELDS(state, fields) {
    state.appliedFilterFields = fields;
  },

  ADD_FIELD_WITH_ERROR(state, label) {
    state.fieldsWithErrors.add(label);
  },

  REMOVE_FIELD_WITH_ERROR(state, label) {
    state.fieldsWithErrors.delete(label);
  },

  RESET_FIELD_ERRORS(state) {
    state.fieldsWithErrors = new Set();
  },

  SET_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  SET_SAVED_FILTERS(state, savedFilters) {
    state.savedFilters = savedFilters;
  },

  SET_SELECTED_ORDER(state, orders) {
    state.selectedOrders = orders;
  },

  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_IS_FORM_EDITED(state, isFormEdited) {
    state.isFormEdited = isFormEdited;
  },

  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },

  SET_IS_ORDERS_INFO_LOADING(state, isOrdersInfoLoading) {
    state.isOrdersInfoLoading = isOrdersInfoLoading;
  },

  SET_SEARCH_TYPE(state, searchType) {
    state.searchType = searchType;
  },

  SET_ORDERS(state, orders) {
    state.orders = orders;
  },

  SET_COMMENT_MODAL_FIELD(state, commentModal) {
    state.commentModal = commentModal;
  },

  RESET_COMMENT_MODAL_FIELDS(state) {
    state.commentModal = {
      comment: "",
      orderId: "",
    };
  },

  ...myOrdersTable,
};
