import orderReprocessRepository from "@/api/repositories/orderReprocess";
import { ORDER_REPROCESS } from "@/api/endpoints";

///?filter=orderType(ZCON)&filter=status(FAILED%20IN%20OFOA)&count=10&anchor=0
export default {
  fetchOrders: (filters) =>
    orderReprocessRepository.get(`${ORDER_REPROCESS.ORDERS}`, { params: filters }),

  reprocessOrders: (request) =>
    orderReprocessRepository.post(`${ORDER_REPROCESS.REPROCESS}`, request),
};
