import { FILTERS_CATEGORIES } from "@/pages/AuditEvent/Filters/config.ts";
import { AUDIT_EVENT_PAGES } from "@/pages/AuditEvent/Orders/constants.ts";

const auditEventState = {
  filterForm: [],
  appliedFilterFields: [],
  componentKey: 0,
  fieldsWithErrors: new Set(),
  searchQuery: "",

  isLoading: false,
  orders: [],
  orderEvents: [],
  isFormEdited: false,
  currentPage: AUDIT_EVENT_PAGES[0].id,
  searchType: FILTERS_CATEGORIES[0].id,
  isOrdersInfoLoading: false,
};

const ordersData = {
  SET_ORDER_TYPES: "SET_ORDER_TYPES",
  SET_IS_ORDERS_EMPTY: "SET_IS_ORDERS_EMPTY",

  SET_IS_FETCHING_ORDERS: "SET_IS_FETCHING_ORDERS",

  SET_TABLE_DATA_LOADING_ERROR: "SET_TABLE_DATA_LOADING_ERROR",
  SET_FILTERS_DATA_LOADING_ERROR: "SET_FILTERS_DATA_LOADING_ERROR",

  SET_MY_ORDERS_SUMMARY: "SET_MY_ORDERS_SUMMARY",

  SET_MY_ORDERS_ERRORS: "SET_MY_ORDERS_ERRORS",
};

const ordersTable = {
  tableParams: {},
  gridApi: {},

  datasource: {},
  columnDef: [],

  orderId: null,
  pageSize: 50,
  pageNumber: 0,
  pageTokens: [null],

  sortedColumnName: null,
  sortedColumnOrder: null,

  tableFilters: {},

  totalAvailablePagesNumber: null,
  totalAvailableOrdersNumber: null,

  userPreferences: [],
};

export default { ...auditEventState, ...ordersData, ...ordersTable };
